import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Project, ProjectAnalytics } from '../classes/project';
import { Settings } from '../classes/settings';
import { DatabaseService } from './database.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { Candidate } from '../classes/candidate';

@Injectable({
  providedIn: 'root'
})

export class ProjectService {

  public static LANGUAGES = [
    { title: '__languageEnglish', value: 'en' },
    { title: '__languageSpanish', value: 'es' },
    { title: '__languageFrench', value: 'fr' },
    { title: '__languagePortuguese', value: 'pt' },
    { title: '__languageBrazilianPortuguese', value: 'pt-BR' },
    { title: '__languageGerman', value: 'de' },
    { title: '__languageCzech', value: 'cs' },
    { title: '__languageNorwegian', value: 'nor' },
    { title: '__languageItalian', value: 'it' },
    { title: '__languageDutch', value: 'nl' },
    { title: '__languageNlBe', value: 'nl-BE' },
    { title: '__languageHungarian', value: 'hu' },
    { title: '__languagePolish', value: 'pl' },
    { title: '__languageDanish', value: 'da' },
    { title: '__languageSwedish', value: 'sv' },
  ];


  private createdProject = new BehaviorSubject('default');
  currentCreatedProject = this.createdProject.asObservable();

  private changeProjectCandidates = new BehaviorSubject('default');
  currentCandidatesProject = this.changeProjectCandidates.asObservable();

  constructor(private httpClient: HttpClient) { }

  createProject(project: Project) {
    return this.httpClient.post<any>(Settings.PROJECT_URL, project);
  }

  getProjectsByClient(...filter: { name, value; }[]): Observable<HttpResponse<Project[]>> {
    const query = DatabaseService.buildFilter(...filter);
    const url = `${Settings.PROJECT_URL}${query}`;
    return this.httpClient.get<Project[]>(url, { observe: 'response' });
  }

  getProject(id: number): Observable<Project> {
    const url = `${Settings.PROJECT_URL}${id.toString()}/`;
    return this.httpClient.get<Project>(url);
  }

  getCandidates(id: number, ...filter: { name, value; }[]): Observable<HttpResponse<Candidate[]>> {
    const query = DatabaseService.buildFilter(...filter);
    const url = `${Settings.PROJECT_CANDIDATES_URL.replace('{id}', id.toString())}${query}`;
    return this.httpClient.get<Candidate[]>(url, { observe: 'response' });
  }

  getProjectDetailCandidate(project_id: number, candidate_id: number): Observable<Candidate> {
    const url = `${Settings.PROJECT_CANDIDATES_URL.replace('{id}', project_id.toString())}${candidate_id}/`;
    return this.httpClient.get<Candidate>(url);
  }

  getFilteredCandidates(id: number, ...filter: { name, value; }[]): Observable<Candidate[]> {
    const query = DatabaseService.buildFilter(...filter);
    const url = `${Settings.PROJECT_CANDIDATES_URL.replace('{id}', id.toString())}${query}`;
    return this.httpClient.get<Candidate[]>(url);
  }

  addCandidates(id: number, candidates: string[], firstnames: string[], lastnames: string[]) {
    const url = `${Settings.PROJECT_ADD_CANDIDATES_URL.replace('{id}', id.toString())}`;
    return this.httpClient.post<any>(url, { emails: candidates, firstnames: firstnames, lastnames: lastnames });
  }

  generateProjectResults(id: number) {
    const url = `${Settings.PROJECT_GENERATE_RESULTS.replace('{id}', id.toString())}`;
    return this.httpClient.get<any>(url);
  }

  generateProjectScores(id: number) {
    const url = `${Settings.PROJECT_GENERATE_SCORES.replace('{id}', id.toString())}`;
    return this.httpClient.get<any>(url);
  }

  sendReminder(project_id: number, candidate_id) {
    const url = `${Settings.PROJECT_SEND_REMINDER_URL.replace('{id}', project_id.toString())}`;
    return this.httpClient.post<any>(url, { candidates: [ candidate_id ], type: "reminder" });
  }

  getAnalytics(id: number): Observable<ProjectAnalytics> {
    const url = `${Settings.PROJECT_ANALYTICS_URL.replace('{id}', id.toString())}`;
    return this.httpClient.get<ProjectAnalytics>(url);
  }

  deleteProject(project_id: any) {
    return this.httpClient.delete<void>(Settings.PROJECT_BY_ID.replace('{id}', project_id));
  }

  modifyProject(project_id: any, client: any, name: string, image: string, process_name: string, time: number, status: any, send_feedback: any, auto_register_enabled: any) {
    const url = `${Settings.PROJECT_BY_ID.replace('{id}', project_id)}`;
    return this.httpClient.put<any>(url, { id: project_id, client: client, name: name, image: image, process_name: process_name, time: time, status: status, send_feedback: send_feedback, auto_register_enabled: auto_register_enabled });
  }

  sendEmailByAction(project: any, candidates: number[], type: string) {
    const url = `${Settings.PROJECT_SEND_FEEDBACK.replace('{id}', project)}`;
    return this.httpClient.post<any>(url, { candidates: candidates, type: type });
  }

  deleteCandidates(project: any, candidates: number[]) {
    const url = `${Settings.PROJECT_DELETE_CANDIDATES.replace('{id}', project)}`;
    return this.httpClient.post<any>(url, { candidates: candidates });
  }

  changeMessage(is_changed: string) {
    this.changeProjectCandidates.next(is_changed);
  }

  createdMessage(is_created: string) {
    this.createdProject.next(is_created);
  }

  checkCandidates(id: number, emails: string[]) {
    const url = `${Settings.PROJECT_CHECK_CANDIDATES_URL.replace('{id}', id.toString())}`;
    return this.httpClient.post<any>(url, { emails: emails });
  }
}
